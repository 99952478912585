<template>
  <div>
    <form v-on:submit.prevent="save" class="column max">
      <b-field label="ID" v-if="!isNew" horizontal>{{ device.id }}</b-field>

      <b-field label="Created at" v-if="device.created_at" horizontal>{{
        device.created_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Updated at" v-if="device.updated_at" horizontal>{{
        device.updated_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Label" horizontal>
        <b-input v-if="isEdit" v-model="device.label" required type="text"></b-input>
        <span v-else>{{ device.label }}</span>
      </b-field>

      <b-field label="Name" horizontal>
        <b-input v-if="isEdit" v-model="device.name" type="text"></b-input>
        <span v-else>{{ device.name }}</span>
      </b-field>

      <b-field label="Enabled" horizontal>
        <b-checkbox v-if="isEdit" v-model="device.enabled">{{ device.enabled ? 'Yes' : 'No' }}</b-checkbox>
        <span v-else>{{ device.enabled ? 'Yes' : 'No' }}</span>
      </b-field>

      <b-field label="API token" horizontal class="token">
        <ApiToken :value="device.api_token"></ApiToken>
      </b-field>

      <b-field label="Note" horizontal>
        <b-input v-if="isEdit" type="textarea" v-model="device.note" rows="2"></b-input>
        <vue-simple-markdown v-else :source="device.note"></vue-simple-markdown>
      </b-field>

      <b-field label="Codec ID" horizontal>
        <b-input v-if="isEdit && isAdmin" v-model="device.codec_id" type="text"></b-input>
        <span v-else>{{ device.codec_id }}</span>
      </b-field>

      <b-field v-if="isAdmin" label="Contract ID" horizontal>
        <b-input v-if="isEdit" v-model="device.contract_id" type="text"></b-input>
        <span v-else>{{ device.contract_id }}</span>
      </b-field>

      <Billing v-model="device.billing" :organization_id="device.organization_id" :editable="isEdit" v-if="isAdmin">
      </Billing>

      <div class="is-divider"></div>

      <b-field label="Group Integration" horizontal v-if="group">
        <IntegrationList v-model="group.integration" :organization_id="group.organization_id"></IntegrationList>
      </b-field>

      <b-field label="Integration" horizontal>
        <IntegrationList v-model="device.integration" :organization_id="device.organization_id" :editable="isEdit">
        </IntegrationList>
      </b-field>

      <b-field label horizontal>
        <div class="buttons">
          <b-button v-if="isNew" type="is-success" icon-left="plus-box" native-type="submit">Add Device</b-button>
          <template v-else-if="!isEdit">
            <b-button type="is-info" icon-left="pencil" :disabled="isFetching" v-on:click="isEdit = true"
              >Edit Device</b-button
            >
            <router-link :to="{ name: 'messages', params: { deviceId: device.id }, query: { page: 1 } }">
              <b-button type="is-info" icon-left="database">Show Messages</b-button>
            </router-link>
          </template>
          <template v-else>
            <b-button type="is-info" icon-left="close" :disabled="isFetching" v-on:click="closeEdit">Close</b-button>
            <b-button type="is-success" icon-left="content-save" native-type="submit" :disabled="isFetching"
              >Save Device</b-button
            >
            <b-button type="is-link" v-on:click="showRenewTokenDialog" icon-left="refresh" :disabled="isFetching"
              >Renew Device Token</b-button
            >
            <b-button
              tag="router-link"
              :to="{ name: 'device-transfer', params: { id: device.id } }"
              icon-left="folder-swap-outline"
              type="is-link"
              :disabled="isFetching"
              >Transfer Device</b-button
            >
            <b-button type="is-danger" v-on:click="showDeleteMessagesDialog" icon-left="trash-can"
              >Delete Device Messages</b-button
            >
            <b-button
              v-if="isAdmin"
              type="is-danger"
              v-on:click="showDeleteDialog"
              icon-left="trash-can"
              :disabled="isFetching"
              >Delete Device</b-button
            >
          </template>
        </div>
      </b-field>
    </form>

    <div class="is-divider"></div>

    <b-field label horizontal v-if="device.id">
      <device-statistics :device_id="device.id" :cache="statistics" />
    </b-field>

    <div class="modal is-active" v-if="deleteDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete this device?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteItem">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>

    <div class="modal is-active" v-if="deleteMessagesDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteMessagesDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete ALL messages from this device?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteMessages">Delete</button>
          <button class="button" v-on:click="closeDeleteMessagesDialog">Cancel</button>
        </footer>
      </div>
    </div>

    <div class="modal is-active" v-if="renewTokenDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Renew token confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeRenewTokenDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to renew API token for this device?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="renewToken">Renew token</button>
          <button class="button" v-on:click="closeRenewTokenDialog">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import ApiToken from '@/components/ApiToken.vue';
import Group from '../models/Group';
import Device from '../models/Device';
import Message from '../models/Message';
import DeviceStatistics from '../components/DeviceStatistics.vue';
import Billing from '../components/Billing.vue';
import IntegrationList from '../components/IntegrationList.vue';

export default {
  name: 'device',
  components: {
    DeviceStatistics,
    Billing,
    IntegrationList,
    ApiToken,
  },
  data() {
    return {
      isEdit: false,
      device: {
        enabled: true,
        billing: {},
        integration: [],
      },
      group: {},
      isFetching: false,
      deleteDialog: false,
      deleteMessagesDialog: false,
      renewTokenDialog: false,
      statistics: {},
    };
  },
  created() {
    if (this.$route.name === 'device-add') {
      this.device.group_id = this.$route.params.groupId;
      this.isEdit = true;
    } else if (this.$route.params.id) {
      this.fetch();
    }
  },
  computed: {
    isNew() {
      return this.device.id === undefined;
    },
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
  },
  methods: {
    async fetch() {
      this.isFetching = true;
      const { data } = await Device.getOne(this.$route.params.id);
      if (data.billing === undefined) data.billing = {};
      this.device = data;
      await this.fetchGroup();
      this.isFetching = false;
    },
    async fetchGroup() {
      const { data } = await Group.getOne(this.device.group_id);
      this.group = data;
    },
    async save() {
      const device = cloneDeep(this.device);
      if (!this.isAdmin) {
        delete device.billing;
      }
      if (this.device.id) {
        const { data } = await Device.update(device);
        this.device = data;
      } else {
        device.device_id = this.$route.params.deviceId;
        const { data } = await Device.add(device);
        if (data.billing === undefined) data.billing = {};
        this.device = data;
        this.$router.push({ name: 'device', params: { id: data.id } });
      }
      this.$toast.success('Device was saved');
      this.isEdit = false;
    },
    async deleteItem() {
      if (!this.deleteDialog) return;
      await Device.delete(this.device.id);
      this.closeDeleteDialog();
      this.$toast.success('Device was deleted');
      this.$router.push({
        name: 'devices',
        params: { groupId: this.device.group_id },
      });
    },
    async deleteMessages() {
      if (!this.deleteMessagesDialog) return;
      await Message.deleteAll(this.device.id);
      this.closeDeleteMessagesDialog();
      this.$toast.success('Device messages were deleted');
    },
    async renewToken() {
      if (!this.renewTokenDialog) return;
      this.device.api_token = 'renew';
      const { data } = await Device.update(this.device);
      this.device = data;
      this.closeRenewTokenDialog();
      this.$toast.success('Device token was renewed');
    },
    showDeleteDialog(e) {
      e.preventDefault();
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    showDeleteMessagesDialog(e) {
      e.preventDefault();
      this.deleteMessagesDialog = true;
    },
    closeDeleteMessagesDialog() {
      this.deleteMessagesDialog = false;
    },
    showRenewTokenDialog(e) {
      e.preventDefault();
      this.renewTokenDialog = true;
    },
    closeRenewTokenDialog() {
      this.renewTokenDialog = false;
    },
    closeEdit() {
      this.isEdit = false;
      this.fetch();
    },
    copyToClipboard(label, text) {
      const toast = this.$toast;
      this.$copyText(text).then(
        () => {
          toast.success(`${label} Copied to clipboard`);
        },
        () => {
          toast.error('Can not copy');
        }
      );
    },
  },
};
</script>
